import AutoSave from '@shoreag/base/AutoSave';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Location } from '@reach/router';
import DatePicker from '@shoreag/base/DatePicker';
import { navigate } from 'gatsby';
import setDashboardURL from '../../../utilities/set-dashboard-url';
import dashboardConstants from '../../../utilities/dashboard-constants';

const RecordFilterForm = ({ type }) => {
  let result;
  switch (type) {
    case 'DatePicker': {
      result = (
        <Field
          component={DatePicker}
          dateDisplayFormat="M/d/yyyy"
          labelSx={{
            fontSize: 1,
          }}
          name="created"
        />
      );
      break;
    }
    default: {
      result = null;
    }
  }
  return (
    <Location>
      {({ location: { pathname, search } }) => {
        const { filters } = dashboardConstants.getCriterias({
          search,
          type: 'records',
        });
        const { values: filterValues } = filters;
        return (
          <Form
            initialValues={filterValues}
            onSubmit={noop}
            render={() => {
              return (
                <>
                  <AutoSave
                    save={(all, changed, added, deleted, values) => {
                      const {
                        filters: updatedFilters,
                        sortCriteria,
                        pageCriteria,
                      } = dashboardConstants.getCriterias({
                        search,
                        type: 'records',
                      });
                      return navigate(
                        setDashboardURL({
                          filters: updatedFilters,
                          filterValues: values,
                          page: {
                            offset: 0,
                            pageSize: pageCriteria.pageSize,
                          },
                          pathname,
                          sort: sortCriteria,
                        }),
                        { replace: true }
                      );
                    }}
                  />
                  {result}
                </>
              );
            }}
          />
        );
      }}
    </Location>
  );
};

RecordFilterForm.propTypes = {
  type: PropTypes.string,
};

RecordFilterForm.defaultProps = {
  type: '',
};

export default RecordFilterForm;
