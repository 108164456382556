import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import { Field, Form } from 'react-final-form';
import { noop } from 'lodash';
import { ThemeContext } from 'styled-components';
import AutoSave from '@shoreag/base/AutoSave';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Spinner from '@shoreag/base/Spinner';
import Button from '@shoreag/base/Button';
import Icon from '../Icon';
import Route from '../Route';
import MultiSelectDropdown from '../MultiSelectDropdown';
import allRecord from '../../graphql/queries/all-record.gql';
import SchemaDropdown from '../SchemaDropdown';
import dashboardConstants from '../../utilities/dashboard-constants';
import RecordFilterForm from './RecordFilterForm';
import PartnerRecordSearch from './PartnerRecordSearch';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import RecordMetrics from './RecordMetrics';
import ViewRecords from '../ViewFileController/ViewRecords';
import setDashboardURL from '../../utilities/set-dashboard-url';
import { filterData } from './utilities/filter-records';
import { UUID_PREFIXES } from '../../utilities/constants';
import DashboardPaginator from '../DashboardPaginator';

const AllRecordsDashboardPage = ({ location, navigate }) => {
  const theme = useContext(ThemeContext);
  const [columnFilterValues, setColumnFilterValues] = useState({});
  const [disabledMatrix] = useState(false); // disabled for QA
  const [showMetrics, toggleMetrics] = useState(false);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'records',
  });
  const { values: filterValues } = filters;

  const { created, schemaId, versionId, partners } = filterValues;
  let partnersList = [];
  if (partners) {
    if (typeof partners === 'string') {
      partnersList = [partners];
    } else {
      partnersList = partners.map((i) => i);
    }
  }

  const initialValues = {
    created: created || [],
    partners: partnersList,
    schemaId: schemaId || '',
    versionId: versionId || '',
  };

  return (
    <Route
      header={{
        rightContainer: <RecordFilterForm type={filters.created.type} />,
        title: 'All Records',
      }}
      isPrivate
    >
      <Form
        initialValues={initialValues}
        onSubmit={noop}
        render={(formContext) => {
          const { values, handleSubmit } = formContext;
          const {
            schemaId,
            versionId,
            columnFilter,
            created,
            partners,
          } = values;

          let queryOption =
            partners?.length > 0
              ? [
                  {
                    field: 'partnerId',
                    type: 'match_phrase',
                    value: partners.length === 1 ? partners[0] : partners,
                  },
                ]
              : [];
          const hasSchemaId = queryOption.some((i) => i.field === 'schemaId');
          if (schemaId && versionId) {
            if (hasSchemaId) {
              queryOption = queryOption.map((i) =>
                i.field === 'schemaId'
                  ? {
                      ...i,
                      value: `${schemaId}:${versionId}`,
                    }
                  : i
              );
            } else {
              queryOption.push({
                field: 'schemaId',
                value: `${schemaId}:${versionId}`,
              });
            }
          }

          const schemaValue = get(
            queryOption.find((i) => i.field === 'schemaId'),
            'value',
            ''
          );
          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                bg: 'grays.1',
                display: 'flex',
                left: 0,
                minHeight: `calc(100vh - (${theme.sizes.heights.header} * 2))`,
                pl: `${theme.sizes.maxWidths.smallForm}`,
                position: 'relative',
                top: 0,
                width: '100%',
              }}
            >
              <AutoSave
                save={(all, changed, added, deleted, values) => {
                  return navigate(
                    setDashboardURL({
                      filters,
                      filterValues: values,
                      page: {
                        offset: 0,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  );
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flex: 'auto',
                  flexDirection: 'column',
                  height: '100%',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: `calc(${theme.sizes.maxWidths.smallForm} - 1rem)`,
                }}
              >
                <PartnerRecordSearch
                  selectedPartners={partners}
                  toggleSelection={(selectedPartners) => {
                    formContext.form.change('partners', selectedPartners);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 4,
                  }}
                >
                  <SchemaDropdown
                    filedProps={{
                      wrapperSx: {
                        flex: 'auto',
                        maxWidth: ['100%', null, null, '30%', '20%'],
                        mr: 2,
                        mt: 0,
                      },
                    }}
                    formContext={formContext}
                    hideSpinner
                    isRequired={false}
                  />
                  {schemaId && versionId && (
                    <Button
                      link={`/schemas/${schemaId}:${versionId}`}
                      sx={{
                        bg: 'grays.3',
                        display: 'flex',
                        mt: 5,
                        px: 3,
                      }}
                    >
                      <Icon svg="view" sx={{ mr: 2, width: '1.2rem' }} />
                      View Schema
                    </Button>
                  )}
                  {disabledMatrix ? null : (
                    <ToolbarButton
                      active={showMetrics}
                      icon="bar-chart"
                      label="Metrics"
                      onClick={() => toggleMetrics(!showMetrics)}
                      sx={{ ml: 'auto', mt: 5 }}
                    />
                  )}
                </Box>
                {showMetrics && <RecordMetrics />}
                <Query
                  fetchPolicy="network-only"
                  notifyOnNetworkStatusChange
                  query={allRecord}
                  skip={!(schemaId && versionId)}
                  variables={{
                    searchBody: JSON.stringify({
                      createdAt: (created || []).map((val) => val.getTime()),
                      limit: pageCriteria.pageSize,
                      offset: pageCriteria.offset,
                      partnerId: partners,
                      record: columnFilterValues,
                      schemaId: schemaValue,
                    }),
                  }}
                >
                  {({ data, loading }) => {
                    const allRecord = get(data, 'allRecord.records', []);
                    const totalCount = get(data, 'allRecord.count', []);

                    const hasPartnerId = queryOption.some(
                      (i) => i.field === 'partnerId'
                    );

                    const modifyRecord = (recordResult) => {
                      const {
                        datasetId,
                        recordId,
                        status,
                        createdAt,
                        transactionType,
                        version,
                        record,
                        partnerId,
                      } = recordResult;

                      const parseRecord = JSON.parse(record);
                      let datasetPrefix = '';
                      if (!datasetId.includes(UUID_PREFIXES.DATASET)) {
                        datasetPrefix = UUID_PREFIXES.DATASET;
                      }
                      /* eslint-disable sort-keys */
                      return {
                        version,
                        status,
                        datasetId: datasetPrefix + datasetId,
                        transactionType,
                        createdAt,
                        recordId,
                        ...parseRecord,
                        partnerId,
                      };
                    };

                    let modifiedRecord = filterData(
                      allRecord.map(modifyRecord)
                    );

                    if (hasPartnerId) {
                      modifiedRecord = allRecord
                        .filter((item) =>
                          queryOption[0].value.includes(item.partnerId)
                        )
                        .map(modifyRecord);
                    }
                    let content = null;
                    if (
                      loading &&
                      !Object.keys(columnFilterValues).length > 0
                    ) {
                      content = <Spinner py="md" />;
                    } else {
                      if (schemaValue) {
                        content = (
                          <>
                            <Box
                              sx={{
                                alignItems: 'center',
                                bg: 'white',
                                display: 'flex',
                                justifyContent: 'space-between',
                                pb: 3,
                              }}
                            >
                              <Box>{totalCount} Records</Box>
                              <ButtonGroups>
                                {/* <Field
                                  component={ToggleSwitch}
                                  label="Filters"
                                  labelSx={{
                                    ml: 0,
                                    mr: 2,
                                    order: 'unset',
                                  }}
                                  mt={0}
                                  name="columnFilter"
                                  type="checkbox"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '180px',
                                  }}
                                /> */}
                                <Field
                                  component={MultiSelectDropdown}
                                  name="attributes"
                                  // options={columns.map((column) => {
                                  //   return {
                                  //     label: column.Header,
                                  //     value: column.Header,
                                  //   };
                                  // })}
                                  options={[]}
                                  placeholder="All Attributes"
                                  wrapperSx={{ flex: 'auto' }}
                                />
                              </ButtonGroups>
                            </Box>
                            <Box
                              sx={{
                                bg: 'white',
                                borderRadius: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                overflow: 'hidden',
                                width: ' 100%',
                              }}
                            >
                              <ViewRecords
                                bodyLoading={false}
                                columnFilterValues={columnFilterValues}
                                data={modifiedRecord}
                                errors={{}}
                                onClick={() => null}
                                setColumnFilterValues={setColumnFilterValues}
                                showColumnFilters={columnFilter}
                                treeData={[]}
                              />
                              <DashboardPaginator
                                enableJumpToPage
                                filters={{
                                  page: pageCriteria.offset,
                                }}
                                goToNextPage={() =>
                                  navigate(
                                    setDashboardURL({
                                      filters,
                                      filterValues: filters.values,
                                      page: {
                                        offset:
                                          pageCriteria.offset +
                                          pageCriteria.pageSize,
                                        pageSize: pageCriteria.pageSize,
                                      },
                                      pathname: location.pathname,
                                      sort: sortCriteria,
                                    }),
                                    { replace: true }
                                  )
                                }
                                goToPage={(page) => {
                                  navigate(
                                    setDashboardURL({
                                      filters,
                                      filterValues: filters.values,
                                      page: {
                                        offset: page * pageCriteria.pageSize,
                                        pageSize: pageCriteria.pageSize,
                                      },
                                      pathname: location.pathname,
                                      sort: sortCriteria,
                                    }),
                                    { replace: true }
                                  );
                                }}
                                goToPreviousPage={() =>
                                  navigate(
                                    setDashboardURL({
                                      filters,
                                      filterValues: filters.values,
                                      page: {
                                        offset:
                                          pageCriteria.offset -
                                          pageCriteria.pageSize,
                                        pageSize: pageCriteria.pageSize,
                                      },
                                      pathname: location.pathname,
                                      sort: sortCriteria,
                                    }),
                                    { replace: true }
                                  )
                                }
                                isClientSidePagination
                                pageTotal={allRecord.length}
                                sx={{
                                  mb: 4,
                                }}
                                total={totalCount}
                              />
                            </Box>
                          </>
                        );
                      }
                      if (modifiedRecord?.length === 0) {
                        content = 'No Result';
                      }
                      if (!schemaValue) {
                        content = 'Select schema and version to view records';
                      }
                    }

                    return (
                      <Card
                        sx={{
                          flex: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          m: 0,
                          pb: 0,
                          pt: 5,
                          px: 5,
                        }}
                      >
                        {content}
                      </Card>
                    );
                  }}
                </Query>
              </Box>
            </Box>
          );
        }}
      />
    </Route>
  );
};

AllRecordsDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default AllRecordsDashboardPage;
