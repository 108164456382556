import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { AuthContext } from '@shoreag/auth';
import Spinner from '@shoreag/base/Spinner';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import { Query } from 'react-apollo';
import allPartner from '../../../graphql/queries/all-partner.gql';
import Icon from '../../Icon';
import tradingPartners from '../../../utilities/get-trading-partners';
import Sidebar from '../../Sidebar';

const iconSx = {
  '&:hover': { fill: 'accentDark' },
  fill: 'accent',
  flex: 'none',
  transition: 'fill 0.2s',
  width: '1rem',
};

const PartnerRecordSearch = ({ selectedPartners, toggleSelection }) => {
  const [toggleSearch, setToggleSearch] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const { user } = useContext(AuthContext);

  const handleClick = (id) => {
    let updatedElements = [...selectedPartners];
    if (selectedPartners.includes(id)) {
      updatedElements = selectedPartners.filter((i) => i !== id);
      toggleSelection(updatedElements);
    } else {
      updatedElements.push(id);
      toggleSelection(updatedElements);
    }
  };

  return (
    <Query
      fetchPolicy="cache-first"
      notifyOnNetworkStatusChange
      query={allPartner}
    >
      {({ data, loading }) => {
        const partners = tradingPartners(get(data, 'allPartner', []), user);
        const selectedOutOfTotal = [
          selectedPartners.length,
          partners.length,
        ].filter((i) => i);
        const isCount = selectedOutOfTotal.length;
        return (
          <Sidebar
            bodySx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 60px)',
              pt: 0,
            }}
            buttonSx={{ bg: 'white', color: 'primary' }}
            customToggle={
              <Box
                alignItems="center"
                bg="white"
                display="flex"
                height="1.4rem"
                justifyContent="center"
                ml="sm"
                onClick={() => setToggleSearch(!toggleSearch)}
                sx={{
                  borderRadius: '50%',
                  flexShrink: 0,
                  transform: toggleSearch ? null : 'rotate(180deg)',
                }}
                width="1.4rem"
              >
                <Icon
                  color="primary"
                  height="1rem"
                  mt="-0.5rem"
                  svg="chevron"
                  width="1rem"
                />
              </Box>
            }
            title={
              <>
                Partners
                {isCount && (
                  <Box
                    as="span"
                    sx={{
                      bg: isCount > 1 ? 'accent' : 'gray5',
                      borderRadius: 3,
                      color: 'white',
                      fontSize: 1,
                      ml: 2,
                      mt: 1,
                      px: 3,
                      py: 1,
                    }}
                  >
                    {selectedOutOfTotal.join('/')}
                  </Box>
                )}
              </>
            }
            titleSx={{
              alignItems: 'flex-start',
              display: 'flex',
              textAlign: 'left',
              width: '100%',
            }}
            wrapperSx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              maxWidth: '100%',
              mt: 0,
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Box
                  sx={{
                    pb: '4',
                  }}
                >
                  {toggleSearch && (
                    <Box
                      as="input"
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search..."
                      sx={{
                        width: '100%',
                      }}
                      value={searchValue || ''}
                      variant="inputs.primary"
                    />
                  )}
                  <Box
                    as="hr"
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border',
                      mt: toggleSearch ? 4 : '',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    overflow: 'hidden',
                    overflowY: 'auto',
                  }}
                >
                  {partners
                    .filter((partner) =>
                      partner.partnerName.match(new RegExp(searchValue, 'i'))
                    )
                    .map((partner) => {
                      const isSelected = selectedPartners.includes(partner.id);
                      return (
                        <Box
                          key={partner.id}
                          fontSize={2}
                          onClick={() => handleClick(partner.id)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            gap: '5px',
                            px: 5,
                            py: 4,
                          }}
                        >
                          <Icon
                            svg={`${isSelected ? 'checkbox' : 'uncheck'}`}
                            sx={iconSx}
                          />
                          {partner.partnerName}
                        </Box>
                      );
                    })}
                </Box>
              </>
            )}
          </Sidebar>
        );
      }}
    </Query>
  );
};

PartnerRecordSearch.propTypes = {
  selectedPartners: PropTypes.arrayOf(PropTypes.string),
  toggleSelection: PropTypes.func.isRequired,
};

PartnerRecordSearch.defaultProps = {
  selectedPartners: [],
};

export default PartnerRecordSearch;
