import React from 'react';
import { Router } from '@reach/router';
import AllRecordsDashboardPage from '../../components/AllRecordsDashboardPage';
import AllRecordsDetailPage from '../../components/AllRecordsDetailPage';
import NotFoundPage from './404';
import DataViewerPage from '../../components/DataViewerPage';

const AllRecordsRouter = (props) => (
  <Router>
    <DataViewerPage {...props} path="/records/:recordId/data" />
    <AllRecordsDashboardPage {...props} path="/records/" />
    <AllRecordsDetailPage {...props} path="/records/:recordId/" />
    <NotFoundPage default />
  </Router>
);

export default AllRecordsRouter;
