import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import { Query } from 'react-apollo';
import { get, noop, slice } from 'lodash';
import Spinner from '@shoreag/base/Spinner';
import Tree from 'react-d3-tree';
import { ParentSize } from '@vx/responsive';
import { Field, Form } from 'react-final-form';
import KeyValuePairs from '../KeyValuePairs';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import Route from '../Route';
import records from '../../graphql/queries/records.gql';
import ToolbarButton from '../ToolbarButton';
import ButtonGroups from '../ButtonGroups';

const createNestedStructure = (edges, parent) => {
  const node = { children: [], name: parent };
  edges
    .filter((edge) => Object.keys(edge)[0] === parent)
    .forEach((edge) => {
      const childName = edge[parent];
      const childNode = createNestedStructure(edges, childName);
      node.children.push(childNode);
    });

  return node;
};

const AllRecordsDetailPage = ({ navigate, recordId }) => {
  const [id, version] = recordId.split(':');
  const [initialParentSize, setInitialParentSize] = useState(null);

  return (
    <Query fetchPolicy="network-only" query={records} variables={{ id }}>
      {({ data, loading }) => {
        const recordVersions = get(data, 'records.versions', []);
        const recordVersion =
          recordVersions.find((v) => v.version === String(version)) ||
          recordVersions[0];

        const datasetData = get(recordVersion, 'dataset', {});
        const partnerData = get(recordVersion, 'partner', {});
        const schemaData = get(recordVersion, 'schema', {});

        const recordEdges = get(data, 'records.recordEdges', []);
        const parsedRecordEdges = Array.isArray(recordEdges)
          ? recordEdges
          : JSON.parse(recordEdges);

        const treeData = createNestedStructure(parsedRecordEdges, 'v1');

        const renderCustomNodeElement = (rd3tProps) => {
          const isHighlighted =
            rd3tProps.nodeDatum.name === `v${recordVersion.version}`
              ? 'accent'
              : 'gray4';

          const handleClick = () => {
            const nodeVersion = slice(rd3tProps.nodeDatum.name, 1);
            navigate(`/records/${id}:${nodeVersion}`, { replace: true });
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                pointerEvents: 'all',
                width: '100%',
              }}
            >
              <Box
                onClick={handleClick}
                sx={{
                  alignItems: 'center',
                  bg: isHighlighted,
                  borderRadius: '2px',
                  color: 'white',
                  display: 'flex',
                  height: '25px',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {rd3tProps.nodeDatum.name}
              </Box>
            </Box>
          );
        };

        return loading ? (
          <Spinner />
        ) : (
          <Route
            header={{
              icon: 'info',
              rightContainer: (
                <Form
                  initialValues={{ version: recordVersion?.version || '' }}
                  onSubmit={noop}
                  render={() => {
                    return (
                      <ButtonGroups>
                        <Field
                          component={Dropdown}
                          name="version"
                          onChange={(value) => {
                            navigate(`/records/${id}:${value}`, {
                              replace: true,
                            });
                          }}
                          options={recordVersions.map((b) => ({
                            label: `Version ${b.version}`,
                            value: b.version,
                          }))}
                          wrapperSx={{
                            maxWidth: ['100%', null, null, '150px'],
                            mb: [5, null, null, 0],
                            minWidth: '150px',
                            mx: [null, null, null, 5],
                          }}
                        />
                        <ToolbarButton
                          icon="documents"
                          label="View Data"
                          link={`/records/${recordId}/data`}
                        />
                      </ButtonGroups>
                    );
                  }}
                />
              ),
              title: get(data, 'records.name', 'Records'),
              type: 'records',
            }}
            isPrivate
          >
            {data.records.recordId !== null &&
            data.records.versions.length > 0 ? (
              <Card mb={5} pt={5}>
                <KeyValuePairs
                  pairs={Object.entries({
                    ...recordVersion,
                    __typename: 'Records',
                    dataset: datasetData,
                    partner: partnerData,
                    schema: schemaData,
                  }).map(enhanceKeyValuePairEntries)}
                />
              </Card>
            ) : (
              <Card mb={5}>No Data</Card>
            )}
            {parsedRecordEdges.length > 0 && (
              <>
                <Box as="h2" mb={2} mt={2}>
                  Record Lineage
                </Box>
                <ParentSize>
                  {({ height, width }) => {
                    if (!initialParentSize && height > 0 && width > 0) {
                      setInitialParentSize({ height, width });
                    }
                    return (
                      <Box
                        sx={{
                          '.rd3t-tree-container .rd3t-link, .rd3t-tree-container .rd3t-leaf-node circle': {
                            stroke: 'gray4',
                          },
                          '.rd3t-tree-container .rd3t-node': {
                            fill: 'accent',
                            stroke: 'gray4',
                          },
                          bg: 'white',
                          borderRadius: 2,
                          height: '300px',
                          userSelect: 'none',
                          width: '100%',
                        }}
                      >
                        <Tree
                          collapsible
                          data={treeData}
                          depthFactor={200}
                          draggable={false}
                          hasInteractiveNodes
                          renderCustomNodeElement={(rd3tProps) => (
                            <foreignObject
                              height={25}
                              pointerEvents="none"
                              width={40}
                              x={-15}
                              y={-15}
                            >
                              {renderCustomNodeElement(rd3tProps)}
                            </foreignObject>
                          )}
                          separation={{ nonSiblings: 0.5, siblings: 0.3 }}
                          zoomable={false}
                          {...(initialParentSize
                            ? {
                                translate: {
                                  x: 100,
                                  y: initialParentSize.height / 2,
                                },
                              }
                            : {})}
                        />
                      </Box>
                    );
                  }}
                </ParentSize>
              </>
            )}
          </Route>
        );
      }}
    </Query>
  );
};

AllRecordsDetailPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  recordId: PropTypes.string,
};

AllRecordsDetailPage.defaultProps = {
  recordId: null,
};

export default AllRecordsDetailPage;
