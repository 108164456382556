export const filterData = (data) => {
  const filteredData = data?.map((obj) => {
    const newObj = { ...obj }; // Create a copy of the object to avoid mutating the original data

    Object.keys(newObj).forEach((key) => {
      if (
        newObj[key] !== null &&
        (typeof newObj[key] === 'object' || Array.isArray(newObj[key]))
      ) {
        delete newObj[key];
      }
    });

    return newObj;
  });

  return filteredData;
};
