import Box from '@shoreag/base/Box';
import React from 'react';
import Card from '@shoreag/base/Card';
import BarChart from '@shoreag/charts/BarChart';
import PieChart from '../../PieChart';

const RecordMetrics = () => {
  const data = [
    { label: 'csv', value: 126 },
    { label: 'json', value: 480 },
    { label: 'xml', value: 326 },
    { label: 'txt', value: 128 },
  ];

  const NUMBERS_DATA = [
    {
      label: 'Premium amount',
      value: '20,000',
    },
    {
      label: 'Total enrollments',
      value: '90.08%',
    },
    {
      label: 'Total endoresments',
      value: '20,000',
    },
    {
      label: 'Total cancellations',
      value: '90.08%',
    },
  ];

  const CHART_DATA = [
    {
      label: 'Policy Transaction Distribution',
      value: (
        <BarChart
          data={data}
          margin={{ bottom: 50, left: 50, right: 10, top: 20 }}
          xAccessor={(d) => d.label}
          xAxisProps={{
            anchorMargin: 10,
            anchorPosition: 'middle',
            hideAxisLine: false,
            hideTicks: false,
            label: 'Duration',
            rotateAnchorDegree: 45,
            rotateLabelDegree: 0,
            strokeWidth: 0.5,
          }}
          yAccessor={(d) => d.value}
          yAxisProps={{
            anchorMargin: 10,
            anchorPosition: 'left',
            hideAxisLine: false,
            hideTicks: false,
            label: 'Count',
            rotateAnchorDegree: 0,
            rotateLabelDegree: 270,
            strokeWidth: 0.5,
          }}
        />
      ),
    },
    {
      label: 'Error Distribution',
      value: <PieChart data={data} legend total={data.length} />,
    },
    {
      label: 'Duplicates',
      value: null,
    },
  ];

  const gap = 4;
  const columnStyle = { pl: gap, pt: gap };
  const cardStyle = { borderRadius: 0, boxShadow: 0, mt: 0, p: 4 };

  const NumberCard = (data) => (
    <Box sx={{ ...columnStyle, width: '25%' }}>
      <Card sx={cardStyle}>
        <Box
          as="h5"
          sx={{
            color: 'grays.4',
            px: 5,
            py: 4,
          }}
        >
          {data.label}
        </Box>
        <Box
          fontSize={6}
          fontWeight="bold"
          sx={{
            px: 5,
            py: 4,
          }}
        >
          {data.value}
        </Box>
      </Card>
    </Box>
  );

  const ChartCard = (data) => (
    <Box sx={{ ...columnStyle, width: '33.33%' }}>
      <Card
        sx={{
          ...cardStyle,
          alignItems: 'center',
        }}
      >
        <Box
          as="h5"
          sx={{
            color: 'grays.4',
            px: 5,
            py: 4,
            textAlign: 'center',
          }}
        >
          {data.label}
        </Box>
        <Box
          fontSize={6}
          fontWeight="bold"
          sx={{
            height: 250,
          }}
        >
          {data.value}
        </Box>
      </Card>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: gap,
        ml: -gap,
        mt: -gap,
      }}
    >
      {NUMBERS_DATA.map((item) => NumberCard(item))}
      {CHART_DATA.map((item) => ChartCard(item))}
    </Box>
  );
};

export default RecordMetrics;
